.card-container {
    width: 200px;
}
.card-hover:hover {
    background-color: hsla(141, 100%, 50%, 0.1);
    transition: ease-in-out 0.2s;
    cursor: pointer;
}
.card-body {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
}

.fs-sm {
    font-size: 12px;
}