.btns-header {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 5px;
}

.btns-header input {
  border: none;
  background-color: #ffffff;
}

a {
  cursor: pointer;
}
