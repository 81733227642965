/* Custom Properties */
:root {
  --blue: hsla(210, 96%, 33%, 1);
  --blue-alpha: hsla(210, 96%, 33%, 0.8);
  --blue-alpha-plus: hsla(210, 96%, 33%, 0.6);
  --vs-background: hsla(0, 0%, 14%, 1);
  --vs-background-alpha: hsla(0, 0%, 14%, 0.8);
  --my-white: hsla(0, 0%, 95%, 1);
  --my-gray: hsla(218, 17%, 35%, 1);
  --bootstrap-danger: hsla(354, 70%, 54%, 1);
  --bootstrap-success: hsla(152, 69%, 31%, 1);
  --bootstrap-secondary: hsla(208, 7%, 46%, 1);
}
/* Restart Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  margin: 0 !important;
}
.accordion {
  --bs-accordion-btn-focus-box-shadow: 0 !important;
}
/* Boton Blue */
.btn-info:disabled,
.btn-info {
  background-color: var(--blue);
  color: white;
  border-color: var(--blue);
}
.btn-info:hover,
.btn-info:active {
  background-color: var(--blue-alpha) !important;
  color: white !important;
  border-color: var(--blue-alpha) !important;
}
.btn-outline-info {
  color: var(--blue);
  border-color: var(--blue-alpha);
}
.btn-outline-info:hover,
.btn-outline-info:active {
  background-color: var(--blue) !important;
  color: white !important;
  border-color: var(--blue) !important;
}
/* Scroll Styles */
.table-scroll-modal {
  max-height: 60vh;
  overflow-y: auto;
}
.table-scroll {
  max-height: 80vh;
  overflow-y: auto;
}
.table-scroll thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tyres-free-scroll {
  max-height: 65vh;
  overflow-y: auto;
}
.tyres-select-scroll {
  max-height: 40vh;
  overflow-y: auto;
}
.list-trip-scroll {
  max-height: 80vh;
  overflow-y: auto;
}
.modal-scroll {
  max-height: 85vh;
  overflow-y: auto;
}
.modal-min-size-lg {
  margin-bottom: 140px;
}
.modal-min-size-sm {
  margin-bottom: 75px;
}
/* Navbar Styles */
.nav-link {
  transition: all 0.3s ease-out;
  border-radius: 5px;
  color: var(--vs-background-alpha) !important;
}
.nav-link:hover {
  color: var(--bootstrap-secondary) !important;
}
.arrow-none .dropdown-toggle::after {
  display:none;
}
/* My Styles */
.header-height {
  height: 2.5rem;
}
.table-head {
  height: 3rem;
}
.bg-blue {
  background-color: var(--blue);
}
.bg-modal-background {
  background-color: hsla(0, 0%, 35%, 0.3);
}
.focus-none:focus {
  box-shadow: none !important;
}
.logo {
  display: block;
  width: 25%;
  margin-top: 5em;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 17%;
}
.login-container {
  max-width: 400px;
}
.bg-my {
  background-color: hsla(0, 0%, 14%, 1) !important;
  border: 2px solid var(--my-gray) !important;
  color: var(--my-white) !important;
}
.modal-plain-header {
  color: var(--vs-background);
}
.custom-container {
  margin: 0 2rem 0 2rem;
}
.form-label {
  margin: 5px 0 3px 0 !important;
  font-size: 14px !important;
}
.text-grey {
  color: grey !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.input-size-lg {
  transform: scale(1.5) !important;
}
.pointer {
  cursor: pointer;
}
/* Bootstrap Querys */
@media (max-width: 768px) {
  .logo {
    display: block;
    width: 75%;
    margin-top: 5em;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .custom-container {
    margin: 0 0.5rem 0 0.5rem;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 1400px) {
}
