.navbar span {
  cursor: pointer;
}

.custom-space li:nth-child(n + 2) {
  margin-left: 20px;
}

.selected-tab li.active {
  color: green !important;
  font-weight: bold;
}

.nav-link-custom {
  padding: 10px;
}

@media (max-width: 650px) {
  .custom-space li:nth-child(n + 2) {
    margin-left: 0px;
  }

  .navbar-nav.flex-row.flex-wrap {
    flex-direction: column;
  }

  .navbar ul {
    gap: 10px;
  }
}
