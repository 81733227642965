.operativo-header-details h3 {
    font-weight: bold;
    color: hsla(117, 70%, 30%, 1);
}
.operativo-header-details p {
    padding-left: 10px;
    padding-bottom: 3px;
    color: darkgray;
    font-size: 15px;
}
@media (max-width: 768px) {
    .operativo-header-details p {
        padding-left: 0px;
    }
  }