.table-primereact td {
  font-size: 13px;
  padding: 0.3rem 0.3rem !important;
}
.table-primereact th {
  font-size: 14px !important;
  padding: 0.5rem 0.5rem !important;
  background-color: #f5f5f5 !important;
  padding-left: 4px !important;
  white-space: nowrap;
}
.table-primereact .p-icon {
  width: 0.8rem !important;
  height: 1rem !important;
}
.p-column-header-content {
  display: flex;
  flex-wrap: nowrap;
}
.table-primereact tr {
  background-color: transparent;
}
.table-primereact tbody tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* tabla abiertas */

.table-abiertas {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  font-size: 13px;
  border-radius: 5px;
  overflow: scroll;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}
.table-abiertas th,
.table-abiertas td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

/*ajuste de css para columnas*/

.table-abiertas th:nth-child(1),
.table-abiertas td:nth-child(1) {
  width: 10%;
}
.table-abiertas th:nth-child(2),
.table-abiertas td:nth-child(2) {
  width: 20%;
}
.table-abiertas th:nth-child(3),
.table-abiertas td:nth-child(3) {
  width: 20%;
}
.table-abiertas th:nth-child(4),
.table-abiertas td:nth-child(4) {
  width: 20%;
}
.table-abiertas th:nth-child(5),
.table-abiertas td:nth-child(5) {
  width: 10%;
}
.table-abiertas th {
  background-color: #f5f5f5;
  font-weight: 600;
}
.table-abiertas tbody tr:hover {
  background-color: #f0f0f0;
}
.table-abiertas tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.table-abiertas tbody tr:last-child td {
  border-bottom: none;
}

.header-grey {
  background-color: #f0f0f0; /* Color gris claro */
  color: #000; /* Color del texto, opcional */
  font-weight: bold; /* Negrita, opcional */
}

/* tabla abiertas  fin*/

.header-details-box {
  border-bottom: 2px solid gainsboro;
}
/* FILTER TEXT */
.table-filter {
  width: 8rem;
  color: #555;
  padding: 0 0 0 5px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid gainsboro;
  font-size: 14px;
  min-height: 1.9rem;
}
.table-scroll-box-small {
  max-height: 28vh;
  overflow-y: auto;
}
.table-scroll-box-medium {
  max-height: 43vh;
  overflow-y: auto;
}
.table-scroll-box-large {
  max-height: 54vh;
  overflow-y: auto;
}
.table-scroll-box-large-xl {
  max-height: 68vh;
  overflow-y: auto;
}
