.details-component-box {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  overflow: hidden;
}
/* SIZE MIN */
.size-box-small {
  min-height: 30vh;
}
.size-box-medium {
  min-height: 50vh;
}
.size-box-large {
  min-height: 65vh;
}
.size-box-large-xl {
  min-height: 80vh;
}
/* SIZE MAX */
.size-box-max-small {
  max-height: 40vh;
}
.size-box-max-medium {
  max-height: 55vh;
}
.size-box-max-large {
  max-height: 65vh;
}
.size-box-max-large-xl {
  max-height: 80vh;
}