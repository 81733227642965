.App {
  text-align: center;
  padding: 5rem 1rem;
}

.error {
  color: #fc8181;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.25rem;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

nav h3 {
  margin: 1rem;
  cursor: pointer;
  color: #718096;
}

nav h3:hover {
  color: white;
}

form {
  margin: 0 auto;
}

form label {
  font-size: 1rem;
  font-weight: bold;
  display: block;
  text-align: left;
  margin: 1rem 0 0.2rem;
}

input,
select {
  width: 100%;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 1px solid #4a5568;
  border-radius: 10px;
  outline: none;
}

input:focus,
select:focus {
  border-color: #4299e1;
}

input[type="checkbox"] {
  width: fit-content;
  margin-right: 0.5rem;
  transform: scale(1.25);
}

.customBtn {
  display: block;
  margin: 1rem 0;
  padding: 0.35rem 0.5rem;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 3px;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.navbar-brand{
  font-size: 16px;
}
.nav-link{
  font-size: 20px;
}
input.input-error,
select.input-error {
  border-color: #fc8181;
}

button:disabled {
  opacity: 0.35;
}