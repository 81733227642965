.table-details td {
    font-size: 13px;
    padding: 0.4rem 0.4rem !important;
  }
  .table-details td b{
    font-size: 14px;
  }
  .custom-striped tbody tr:nth-child(even) {
    background-color: #f0f0f0;
  }
  .header-details-box {
    border-bottom: 2px solid gainsboro;
  }
  /* SCROLL */
  .table-row-scroll-box-small {
    max-height: 28vh;
    overflow-y: auto;
  }
  .table-row-scroll-box-medium {
    max-height: 43vh;
    overflow-y: auto;
  }
  .table-row-scroll-box-large {
    max-height: 54vh;
    overflow-y: auto;
  }
  .table-row-scroll-box-large-xl {
    max-height: 68vh;
    overflow-y: auto;
  }