.table-main {
  overflow-x: auto;
}
.table-main-th th {
  background-color: #f0f0f0 !important;
  padding-top: 1.1rem !important;
  padding-bottom: 1.1rem !important;
  border-top-width: 2px !important;
  white-space: nowrap;
}
.table-main-th-sm th {
  background-color: #f0f0f0 !important;
  padding-top: 1.1rem !important;
  padding-bottom: 1.1rem !important;
  border-top-width: 2px !important;
  white-space: nowrap;
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
  font-size: 15px !important;
}
.p-column-header-content {
  display: flex;
  flex-wrap: nowrap;
}
.table-main-cell td {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 13px;
}
.table-main-cell-sm td {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 11px !important;
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}
.header-main {
  background-color: #f0f0f0;
  padding: 0.9rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
/* FILTER TEXT */
.table-main-filter {
  width: 10rem;
  color: #555;
  padding: 0 0 0 5px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid gainsboro;
  font-size: 14px;
  min-height: 1.9rem;
}
/* SCROLL SIZE */
.table-main-scroll-medium {
  max-height: 60vh;
  overflow-y: auto;
}
.table-main-scroll-large {
  max-height: 70vh;
  overflow-y: auto;
}
.table-main-scroll-large-xl {
  max-height: 80vh;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .table-main td:first-child,
  .table-main th:first-child {
    padding-left: 0.4rem !important;
  }
  .table-main th {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }
  .table-main td {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }
}
